<template>
  <div class="claim-data-table">
    <v-form class="search" @submit.prevent="search">
      <dropdown
        class="dropdown"
        v-model="dashboard.searchType"
        :items="searchTypes"
        @change="setSearchType($event)"
      />
      <textInput
        class="input"
        v-model="dashboard.search"
        :placeholder="searchTypeDisplayValue"
        @input="setSearch($event)"
        prepend-inner-icon="mdi-magnify"
      />
      <dropdown
        class="dropdown"
        v-if="dashboard.searchType !== 'claimId'"
        v-model="dashboard.daysToLookBack"
        :items="daysToLookBackOptions"
        @change="setSearchDays($event)"
      />
      <qvoButton type="submit">Search</qvoButton>
    </v-form>

    <dataTable
      :headers="headers"
      :items="isLoading ? [] : claimList"
      item-key="claimId"
      :loading="isLoading"
      @click:row="viewCase"
    >
    </dataTable>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import dataTable from "@/qux-common/qux22/components/organisms/data-table";
import dropdown from "@/qux-common/qux22/components/molecules/dropdown";
import textInput from "@/qux-common/qux22/components/molecules/input";
import qvoButton from "@/qux-common/qux22/components/molecules/button";

const ALL = 99999;

export default {
  components: {
    dataTable,
    dropdown,
    textInput,
    qvoButton,
  },
  data() {
    return {
      searchTypes: [
        { text: "Claim ID", value: "claimId" },
        { text: "Account #", value: "accountNumber" },
        { text: "Customer ID", value: "customerId" },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.pendingCalls.includes("/claim/list");
    },
    daysToLookBackOptions() {
      const allOption = { text: "All", value: ALL };
      if (this.config.DaysToLookBackOptions) {
        const options = this.config.DaysToLookBackOptions.map(
          (option) => option.DaysToLookBack
        );
        const dayOptions = options.map((option) => ({
          text: this.formatDays(option),
          value: option,
        }));
        dayOptions.push(allOption);
        return dayOptions;
      }
      return [allOption];
    },
    /**
     * Data table headers
     */
    headers() {
      const headers = [
        {
          text: "Claim ID",
          align: "start",
          value: "claimId",
        },
        {
          text: "Status",
          align: "start",
          value: "claimStatus",
        },
        {
          text: "Account Number",
          align: "start",
          value: "accountNumber",
          sortable: false,
        },
        {
          text: "Account Holder",
          align: "start",
          value: "accountHolder",
          sortable: false,
        },
        {
          text: "Customer ID",
          align: "start",
          value: "customerId",
          sortable: false,
          hide: () => this.dashboard.searchType !== "customerId",
        },
        {
          align: "end",
          value: "action",
          sortable: false,
        },
      ];
      // hide is a property not used by the table, but used here for determining if the column should be filtered out or not
      return headers.filter(
        (header) => !(typeof header.hide !== "undefined" && header.hide())
      );
    },
    searchTypeDisplayValue() {
      return (
        this.searchTypes.find(
          (type) => type.value === this.dashboard.searchType
        ).text || ""
      );
    },
    ...mapGetters({
      config: "getConfig",
      pendingCalls: "getPendingCallList",
    }),
    ...mapState({
      claimList: (state) => state.claim.claimList,
      dashboard: (state) => state.dashboard,
    }),
  },
  methods: {
    formatDays(numDays) {
      if (numDays > 364) {
        const years = Math.round(numDays / 365);
        return years === 1 ? `${years} Year` : `${years} Years`;
      }
      if (numDays > 90) return `${Math.round(numDays / 30)} Months`;
      return `${numDays} Days`;
    },
    /**
     * Resets the search field
     */
    resetSearch() {
      this.$store.dispatch("resetSearch");
    },
    search() {
      const { search, searchType, daysToLookBack } = this.dashboard;
      this.$store.dispatch("retrieveClaimList", {
        daysToLookBack:
          this.dashboard.searchType === "claimId" ? ALL : daysToLookBack,
        searchType,
        search: search.trim(),
      });
    },
    /**
     * Sets the search term
     */
    setSearch(term) {
      this.$store.dispatch("setSearch", term);
    },
    /**
     * Sets the search type
     */
    setSearchDays(days) {
      this.$store.dispatch("setSearchDays", days);
    },
    /**
     * Sets the search type
     */
    setSearchType(type) {
      this.$store.dispatch("setSearchType", type);
    },
    /**
     * View case when selected
     * @param {Object} item - case item
     */
    viewCase(item) {
      this.$emit("viewCase", item);
    },
  },
  created() {
    // Perform a search on load in case there are stored search values
    if (this.dashboard.search) this.search();
  },
};
</script>

<style lang="scss" scoped>
.search {
  margin: $qvo-spacing-24 0;
  display: flex;
  gap: $qvo-spacing-24;
  .dropdown {
    width: 162px;
  }
  .input {
    width: 446px;
  }
}
.v-data-table {
  &::v-deep {
    tr {
      cursor: pointer;
    }
  }
}
.v-list-item {
  cursor: pointer;

  &:hover {
    background: $qvo-color-grey-100;
  }
}
</style>